@import './fonts/fonts.scss';

html {
  font-size: 10px;
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  margin: 0;
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.6rem;
  color: #1F2659;
  background-color:#F0F0F7;
  line-height: 1.5;
  padding: 3rem 2rem;
  font-weight: 500;
  min-height: 100vh;
}

svg {
  max-width: 15rem;
}

h1 {
  font-size: 4rem;
  line-height: 1.1;
}

label {
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 1.2;
}

input {
  margin-bottom: 1.5rem;
}

.flex {
  display: flex;
  margin: .5rem 0;
  align-items: center;
}

.flex input {
  margin: 1rem 1rem 1.5rem .5rem;
}

.flex label {
  padding: 1rem 0;
}

label span {
  text-decoration: underline;
  font-weight: 500;
}

button {
  border-radius: 4px;
  background-color: #027D65;
  padding: 1.25rem 2rem;
  color: white;
  font-size: 1.5rem;
  margin-top: 2rem;
  font-weight: 600;
  cursor: pointer;
  -webkit-appearance: none;
  appearance: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  outline: none;
  box-shadow: none;
  border-style: none;
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
}

button[disabled] {
  opacity: .5;
  cursor: not-allowed;
}

button:focus {
  border: 4px solid orange;
  transform: translate3d(-4px, -4px, 0);
}

input[type="text"], input[type="tel"] {
  -webkit-appearance: none;
  appearance: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  outline: none;
  box-shadow: none;
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  width: 100%;
  border: 1px solid #1F2659;
  border-radius: 4px;
  padding: 1rem 1.25rem;
  font-weight: 500;
  font-size: 1.6rem;
}

input[type="checkbox"] {
  min-width: 1.5rem;
  min-height: 1.5rem;
}

.popup-overlay {
  position: fixed;
  height: 100vh;
  width: 100vw;
  padding: 4rem 2rem;
  top: 0;
  left: 0;
  display: flex;
  background-color: #F0F0F780;
  align-items: center;
}

.popup {
  background-color: white;
  padding: 2rem 2rem 4rem;
  width: 100%;
  max-height: 100%;
  height: max-content;
  border: 1px solid #1F2659;
  border-radius: 4px;
  overflow-y: auto;
}

ol {
  padding: 0 2rem;
  font-weight: 600;
}

h1 + button {
  margin-top: 0;
  margin-bottom: 1rem;
}