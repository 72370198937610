@font-face {
  font-family: 'Poppins'; font-style: normal;
  font-weight: 400;
  src: local('Poppins'), url(./Poppins-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins'; font-style: normal;
  font-weight: 100;
  src: local('Poppins'), url(./Poppins-Thin.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins'; font-style: normal;
  font-weight: 200;
  src: local('Poppins'), url(./Poppins-ExtraLight.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins'; font-style: normal;
  font-weight: 300;
  src: local('Poppins'), url(./Poppins-Light.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins'; font-style: normal;
  font-weight: 500;
  src: local('Poppins'), url(./Poppins-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins'; font-style: normal;
  font-weight: 600;
  src: local('Poppins'), url(./Poppins-SemiBold.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins'; font-style: normal;
  font-weight: 700;
  src: local('Poppins'), url(./Poppins-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins'; font-style: normal;
  font-weight: 800;
  src: local('Poppins'), url(./Poppins-ExtraBold.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins'; font-style: normal;
  font-weight: 900;
  src: local('Poppins'), url(./Poppins-Black.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-weight: 400;
  font-style: italic;
  src: local('Poppins'), url(./Poppins-Italic.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-weight: 100;
  font-style: italic;
  src: local('Poppins'), url(./Poppins-ThinItalic.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-weight: 200;
  font-style: italic;
  src: local('Poppins'), url(./Poppins-ExtraLightItalic.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-weight: 300;
  font-style: italic;
  src: local('Poppins'), url(./Poppins-LightItalic.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-weight: 500;
  font-style: italic;
  src: local('Poppins'), url(./Poppins-MediumItalic.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-weight: 600;
  font-style: italic;
  src: local('Poppins'), url(./Poppins-SemiBoldItalic.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-weight: 700;
  font-style: italic;
  src: local('Poppins'), url(./Poppins-BoldItalic.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-weight: 800;
  font-style: italic;
  src: local('Poppins'), url(./Poppins-ExtraBoldItalic.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-weight: 900;
  font-style: italic;
  src: local('Poppins'), url(./Poppins-BlackItalic.ttf) format('truetype');
}